// Here you can add other styles

// loader
.panel__refresh.active-spinner .spin-circle {
  border-right-color: $primary !important;
}
.active-spinner .spin-circle {
  border-right-color: $primary !important;
}

// route loader
.sk-child {
  background-color: $primary !important;
}

.alert p {
  margin: 0 !important;
}

.custom-control-indicator {
  background-color: #ffffff;
  border: 1px solid #ddd;
}
.overlay {
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 1021;
  opacity: 1;
}

.show-overlay {
  .overlay {
    display: block;
  }
}

.confirmation-overlay {
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 1050;
  opacity: 1;
}
.show-confirmation-overlay {
  .confirmation-overlay {
    display: block;
  }
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-copy {
  cursor: copy;
  .modal-popup {
    cursor: auto;
  }
}
.no-box-shadow {
  box-shadow: none;
}
.summary_list {
  width: 100%;
  line-height: 1.5;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
  li {
    list-style: none;
    width: 20%;
    padding-right: 0.75rem;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    min-width: 0%;
    .summary_label {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      padding-right: 1rem;
      white-space: nowrap;
      color: rgb(84, 105, 141);
      line-height: 1.5;
      text-transform: uppercase;
      width: 100%;
    }
    .summary_value {
      color: #000;
    }
  }
}

.icon-lg {
  font-size: 2.5rem;
}
.icon-md {
  font-size: 1.875rem;
}
.icon-sm {
  font-size: 1rem;
}

.refresh-container {
  background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  z-index: 5;
  cursor: wait;
  overflow: hidden;
}

.change-log {
  margin: 0 0 0 15px;
  padding: 0;
  color: #61677a;
  list-style-type: none;
  li {
    border-left: 1px solid #ddd;
    padding-left: 20px;
    position: relative;
    padding-bottom: 20px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .time-log {
    .date {
      background: #4da9e6;
      color: #fff;
      padding: 3px 10px;
      border-radius: 12px;
      margin-right: 10px;
    }
    .circle {
      width: 25px;
      height: 25px;
      border-radius: 12px;
      position: absolute;
      left: -13px;
      background: #e4f1fd;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .message {
    background: #f5f5f5;
  }
}

.perfect-scroll {
  max-height: 340px;
  height: auto;
  &.table-content-scroll {
    max-height: 200px;
    max-width: 200px;
  }
}
.filter-select {
  padding: 0;
  border: 1px solid #aaaaaa;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  max-width: 800px;
  position: absolute;
  z-index: 1001;
  width: 92%;
  li {
    position: relative;
    margin: 0;
    padding: 3px 1em 3px 0.4em;
    cursor: pointer;
    min-height: 0;
    display: block;
    font-weight: 400;
    line-height: 1.42857;
    white-space: nowrap;
    list-style: none;
  }
}

.lookup_menu {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(221, 219, 218);
  border-radius: 0.25rem;
  z-index: 1;
  position: absolute;
  width: 100%;
  margin-top: 0.25rem;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      a {
        height: 100%;
        padding: 0.25rem 0.75rem;
        min-height: 1.5rem;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 0.25rem;
      }
    }
  }
}
.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.min-305 {
  min-height: 352px;
}
.min-h-200 {
  min-height: 200px;
}
.min-h-100 {
  min-height: 100%;
}

.w-200 {
  width: 200px;
}
.w-250 {
  width: 250px;
}
.custom-accordion-header {
  padding: 0.7rem;
  background: #edf4fa;
}
.chartjs-render-monitor {
  margin-top: 10px;
}
.accordion-wrap {
  .card_payment-wrap {
    .card-header {
      background: #fff;
      border: none;
      .card {
        border: 1px solid #f7f7f7;
      }
      a {
        padding: 0;
        &:before {
          position: absolute;
          right: 8px;
          top: 8px;
          font-size: 19px;
          display: block;
          font-weight: 700;
          color: #565454;
        }
        &[aria-expanded="true"] {
          &:before {
            content: "-";
          }
        }

        &[aria-expanded="false"] {
          &:before {
            content: "+";
          }
        }
      }
    }
    .action-icon {
      margin-top: 10px;
    }
    .border-div {
      height: 43px;
      border-left: 3px solid;
      padding-left: 1px;
    }
    .label {
      float: left;
      margin-top: 12px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .scanned-packages {
      > .card {
        border-radius: 3px;
        border-color: $red;
        .card-header.bg-danger a:before {
          color: #fff;
        }
        .card-body {
          padding: 0;
        }
      }
      .card {
        margin-bottom: 0;
      }
    }
    .related-packages {
      > .card {
        > .card-body {
          padding: 0;
        }
      }
      .card {
        margin-bottom: 0;
      }
    }
  }
  .card {
    .problems-reported-wrap & {
      margin-bottom: 0;
    }
  }
  &.package-list {
    .card_payment-wrap .card-header a:before {
      top: 3px;
    }
  }
}
.links-btn {
  color: $secondary-link-color;
  &:hover {
    color: $secondary-link-hover-color;
  }
}
.text-link {
  color: $text-link-color !important;
  &:hover {
    color: $text-link-hover-color !important;
  }
}
.cdk-overlay-container {
  z-index: $datepicker-overlay-index;
  .owl-dialog-container {
    overflow-x: hidden;
    max-width: 300px;
    padding: 10px;
    .owl-dt-timer,
    .owl-dt-calendar {
      max-width: 100%;
      height: auto;
      min-height: auto;
    }
  }
  .owl-dt-dialog-container {
    margin: 0;
  }
}
.orderDetails-wrap {
  min-height: 195px;
}
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  padding-right: 1.25rem;
  position: relative;
  max-height: 300px;
}

.custom-scroll ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-scroll ::-webkit-scrollbar-thumb {
  background: #dadada;
  cursor: pointer;
  border-radius: 2.5px;
}

.custom-scroll ::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.fw-600 {
  font-weight: 600;
}
.calendar-wrap {
  position: relative;
  .form-control {
    padding-right: 30px;
  }
  .fa-calendar {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}
.update-pricing {
  .form-control {
    font-size: 0.75rem;
    padding-left: 5px;
    padding-right: 5px;
    &[type="number"] {
      padding-right: 0;
    }
  }
}
.owl-dt-container-control-button .owl-dt-control-button-content {
  color: $secondary;
}
.status-progress {
  display: flex;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  .status-item {
    flex: 1;
    background: $gray-100;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    @media (max-width: 767px) {
      flex: auto;
      margin: 5px 0;
      font-size: 14px;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
    &:after {
      content: "";
      border-top: 20px solid white;
      border-left: 15px solid transparent;
      border-bottom: 20px solid white;
      position: absolute;
      right: 0;
      top: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      border-left: 15px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
  }
}
.img-holder {
  min-width: 100px;
  position: relative;
  min-height: 100px;
}
img.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
// Calendar
.cal-month-view .cal-header {
  background: #edf4fa;
  padding: 5px 0;
  &.cal-cell-row:hover {
    background: #edf4fa;
  }
}
.cal-month-view .cal-cell-row .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.5em;
}
.cal-month-view .cal-events .cal-event {
  width: 28px;
  height: 28px;
  font-size: 12px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cal-month-view .cal-cell-row,
.calendar-detail {
  .cal-event {
    font-family: "FontAwesome";
    &.call {
      &:before {
        content: "\f095";
      }
    }
    &.email {
      &:before {
        content: "\f0e0";
      }
    }
    &.visit {
      &:before {
        content: "\f2b5";
      }
    }
    &.Meeting {
      &:before {
        content: "\f0c0";
      }
    }
    &.drop {
      &:before {
        content: "\f0d1";
      }
    }
    &.task {
      &:before {
        content: "\f0ae";
      }
    }
    &.hour {
      &:before {
        content: "\f017";
      }
    }
    &.note {
      &:before {
        content: "\f24a";
      }
    }
    &.point {
      &:before {
        content: "\f071";
      }
    }
  }
}
.calendar-detail {
  // .card-header {
  //   padding-left: 60px;
  // }
  .cal-event {
    width: 30px;
    height: 30px;
    border-radius: 23px;
    color: #fff;
    position: absolute;
    top: -40px;
  }
}

.modal-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-popup-open {
  overflow: hidden;
  .modal-popup {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.text-shadow {
  text-shadow: 2px 1px 1px rgba(0, 0, 0, 1);
}
::-webkit-file-upload-button {
  cursor: pointer;
}
// hr module
.activity-tab {
  display: flex;
  .card {
    flex-direction: row;
    border: 0;
    .card-header {
      border-radius: 0;
      a {
        padding: 0;
      }
    }
    .card-body {
      padding: 0;
    }
  }
  .card-header .btn.btn-link {
    margin: 0;
    padding: 0;
    color: #fff;
  }
}
.year-tab,
.month-tab {
  .cal-month-view {
    .cal-events {
      max-height: 100px;
      overflow: auto;
    }
    .cal-open-day-events {
      padding: 0;
      & > div {
        padding: 5px 15px;
      }
    }
  }
}
.cal-month-view {
  .cal-days {
    .cal-events {
      max-height: 130px;
      overflow-y: auto;
    }
    .cal-cell-top {
      min-height: auto;
    }
    .cal-day-badge {
      margin-top: 5px;
    }
    .cal-day-number {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
}
.production-tab {
  .nav-tabs {
    flex-wrap: nowrap;
    .nav-link {
      height: 100%;
      font-size: 12px;
      line-height: 16px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .work-order-number {
    width: 120px;
  }
}
label.report-label {
  min-width: 90px;
}
.bg-meeting {
  background-color: $bg-meeting !important;
}
.spinner-container {
  position: relative;
}
.w-100px {
  width: 100px;
}
.w-150px {
  width: 150px;
}
.ml_15px {
  margin-left: -15px;
}
.active-spinner .spin-circle {
  margin-top: 1em;
  margin-left: 1em;
}
.word-break-all {
  word-break: break-all;
}

.price-adjustment-list-block {
  padding-top: 10px;
  padding-bottom: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid #ddd !important;
  }
}

.contact-img-holder {
  padding: 5px;
  float: left;
  margin-right: 10px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.table-wrapper-scroll-y {
  display: block;
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.logo-nav {
  padding: 0;
  list-style-type: none;
  li {
    display: inline-block;
    padding-right: 2px;
  }
  .h6 {
    font-size: 12px;
  }
}
.stitch-table {
  font-size: 12px;
}

.card-top-buttons {
  padding: 1.3rem;
  right: 0;
  top: 0;
}
.card-border-top {
  border-top: 1px solid #eee;
}
.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 50px;
}
.color_swatch-block {
  float: left;
  min-width: 15px;
  height: 15px;
  border: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  float: left;
  margin: 0 5px 5px 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border-radius-0 {
  border-radius: 0;
}

.app-header {
  .dropdown-toggle {
    &::after {
      color: #fff;
      display: none;
    }
  }
}
.order-status-wrap .order-status {
  .status-label .status-list li.list-group-item.selected {
    color: #fff;
    background-color: #57b2e9;
    border-color: #62bbec;
    cursor: default;
    &:hover {
      color: #fff;
      background-color: #57b2e9;
      border-color: #62bbec;
      cursor: default;
    }
  }
  .status-label .status-list {
    top: 35px;
  }
}

.autocomplete-wrap {
  position: relative;
  clear: both;
  i {
    position: absolute;
    top: 11px;
    right: 8px;
    font-size: 14px;
    color: #939393;
    font-weight: 300;
  }
}
.table-left-border {
  width: 3px;
  float: left;
  margin: -9px 0px -9px -8px;
  height: 60px;
}

ngb-accordion [role="tab"].card-header {
  position: relative;
  padding: 10px;
  button {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0;
    color: #fff;
    &:before {
      position: absolute;
      right: 8px;
      top: 0;
      font-size: 28px;
      display: block;
      font-weight: 700;
      color: #fff;
    }
    &[aria-expanded="true"] {
      &:before {
        content: "-";
        font-size: 42px;
        font-weight: 600;
        top: -14px;
      }
    }

    &[aria-expanded="false"] {
      &:before {
        content: "+";
      }
    }
  }
}
.add-item-wrap {
  h4 {
    font-size: 1.1rem;
  }
  .add-icon {
    font-size: 4rem;
    color: #a4a4a4;
  }
}

.toggle-button {
  position: absolute;
  right: 20px;
  z-index: 1;
}

.night-mode {
  background-color: #eee;
}

/*Download Logo*/
.img-preview-wrap {
  align-items: center;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  position: relative;
  a:hover > .middle {
    opacity: 1;
  }
  .middle {
    width: 100%;
    height: 100%;
    display: table;
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    bottom: 0;
    top: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    .middle-inner {
      width: 100%;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
    }
    .gl-item-icon {
      margin-bottom: 0;
      font-size: 36px;
      text-align: center;
      color: #fff;
    }
    .gl-item-title {
      margin-bottom: 0;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
    }
  }
}

.btn-wide {
  padding: 0.375rem 1.5rem;
  font-size: 0.81818rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  float: right;
}
.address_change_block {
  .card {
    float: left;
    min-height: 125px;
    width: 100%;
  }
  .active {
    background: #edf4fa;
    &:after {
      content: "\f00c";
      font-family: fontawesome;
      position: absolute;
      right: 15px;
      top: 15px;
      color: #4caf50;
    }
  }
}
.home-page .card-body {
  text-align: center;
  min-height: 180px;
  .icon {
    .fa {
      font-size: 55px;
    }
  }
  span.count {
    font-size: 35px;
  }
  span.status {
    font-size: 13px;
    line-height: 16px;
  }
}
.sidebar-fixed {
  .sidebar {
    .sidebar-nav {
      .sidebar-filter-wrap {
        padding: 10px 0;
        text-align: center;
        background: #fff;
        box-sizing: border-box;
        color: #485c67;
        select.form-control {
          width: 150px;
          display: inline-block;
          margin-left: 3px;
        }
      }
    }
  }
}

.attribute-header {
  border-bottom: 1px solid #e0e0e0;
}
.attribute-wrapper {
  background: #e7eaf3;
  .card {
    border: none;
    margin-bottom: 5px;
  }
  .attribute-accordion {
    .card-header {
      background: #fff;

      a,
      button {
        color: #333;
        font-size: 12px;
        font-weight: 600;
        text-transform: capitalize;
        &[aria-expanded="true"] {
          &:before {
            content: "-";
            float: right;
            font-size: 35px;
            line-height: 10px;
            color: #333;
            top: 14px;
          }
        }

        &[aria-expanded="false"] {
          &:before {
            content: "+";
            float: right;
            font-size: 21px;
            line-height: 20px;
            color: #333;
            top: 11px;
          }
        }
      }
    }
    .card-body {
      padding: 0;
    }
    .attribute-accordion-wrap {
      background: #cfd7ef;
      padding: 10px 0 1px 45px;
      min-height: 35px;
      border: 1px solid #fff;
      border-top: 0;
      .attribute-list {
        margin-bottom: 5px;
      }
    }
  }
}
.attribute-right-col {
  border: 1px solid #e0e0e0;
  height: 100%;
  .attribute-accordion-wrap {
    margin: 0;
    padding: 0;
    list-style: none;
    .attribute-accordion-list {
      padding: 3px 0;
      margin: 0;
    }
  }
}
ngb-accordion {
  &.attribute-ecommerce-category {
    .card {
      border: none;
      margin-bottom: 5px;
    }
    .card-header {
      background: #fff;
      button {
        span {
          color: #333;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
        }
        &[aria-expanded="true"] {
          &:before {
            content: "-";
            float: right;
            font-size: 35px;
            line-height: 10px;
            color: #333;
            top: 12px;
          }
        }

        &[aria-expanded="false"] {
          &:before {
            content: "+";
            float: right;
            font-size: 21px;
            line-height: 20px;
            color: #333;
            top: 13px;
          }
        }
      }
    }
    .collapse {
      background: #f0f3f5;
    }
    .attribute-list-category {
      .card-header {
        background: #f0f3f5;
        button {
          span {
            color: #333;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            padding-left: 40px;
            float: left;
          }
          &[aria-expanded="true"] {
            &:before {
              content: "-";
              font-size: 35px;
              line-height: 10px;
              color: #333;
              top: 12px;
              left: 3px;
              right: auto;
            }
          }

          &[aria-expanded="false"] {
            &:before {
              content: "+";
              font-size: 21px;
              line-height: 20px;
              color: #333;
              top: 10px;
              left: 3px;
              right: auto;
            }
          }
        }
      }
      .collapse {
        .card-body {
          padding: 0px 0px 0px 75px;
        }
      }
    }
  }
}
button {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.problem-summary-text {
  text-transform: capitalize;
  .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    &.card-child {
      padding-left: 30px;
    }
  }
  .problem-summary-count {
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: #63c2de;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
.remove-btn-wrap {
  width: 35px;
  text-align: right;
  .btn {
    margin-top: 29px;
    background-color: transparent;
    padding-right: 0;
  }
}
.w-100 {
  width: 100%;
}

.disabled-span {
  background-color: #c2cfd6;
  opacity: 1;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border: 1px solid #E0E0E0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.main-container{
  margin-top: 60px;
  margin-left: 235px;
  padding: 15px;
  -ms-overflow-x: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  overflow: hidden;

}
* {
  will-change: auto !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield; 
}
.edit-logo .logo-nav label {
  font-size: .75rem;
}
.table-column-wrap {
  max-width: 400px;
  word-wrap: break-word;
}

@media screen and (max-width: 992px) {
  .main-container {
      margin-left: 0px !important;
  }
}

.sr-icon-box {
  width: 60px;
  height: 60px;
  text-align: center;
  color: #fff;
  font-weight: 800;
  line-height: 66px;
  i {
    font-size: 25px;
    font-weight: 700;
  }
}

.div-wrapper-scroll-y {
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.target-table {
  .custom-control {
    &.custom-checkbox{
      display: inline-block;
    }
  }
}

.w-90p {
  width: 90%;
}

.calendar-wrap .fa-close {
  position: absolute;
  right: 40px;
  top: 10px;
}
.table-bottom {
  th, td {
    vertical-align: bottom;
  }
}
.icon-wrap {
  background: #f5f5f5;
  h3 {
    color: #8D92A0;
    i {
      font-size: 1.5rem;
    }
  }
  .bg-div {
    width: 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}
.block-label {
  font-size: 0.7rem;
  font-weight: 500;
}