@import "../bootstrap-variables";
@import "variables";
.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: middle;
  }
  tr:nth-child(2n) {
    background: #FBFBFB;
  }
}

.table-align-middle {

  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}
.table thead th {
  font-weight: 600;
  font-size: 14px;
  padding: .6rem;
  color: #414141;
  vertical-align: top;
  &.cursor-pointer {
    span.table-head {
      padding-right: 12px;
      .sort-icon {
        top: 5px;
        right: 0;
      }
    }
  }
  .fa.pull-right {
    margin-top: 3px;
  }
}
select.form-control.page-count {
  display: inline-block;
  width: 65px;
  padding-left: 5px;
  margin: 0px 5px;
  @media (max-width: 767px) {
    margin: 5px 0;
  }
}
.table .thead-gray {
  th {
    background-color: #D8D8D8;
  }
}
.table {
  border: 1px solid #e0e0e0;
  lib-salesonepro-icon {
    color: #505461;
    font-size: 16px;
  }
  ul.list-inline {
    margin-bottom: 4px;
  }
  thead {
    th {
      background: #edf4fa;
      border: 0;
    }
  }
}
.table-search-wrap {
  position: relative;
  @media (max-width: 767px) {
      margin: 5px 0;
  }
  .search-icon {
    position: absolute;
    top: 8px;
    left: 25px;
    font-size: 20px;
    color: #61677A;
  }
  .form-control {
    padding-left: 40px;
  }
}
.text-light-blue {
  color: #3CA8ED;
}
.table-bordered a {
  color: $secondary-link-color;
  &:hover {
    color: $secondary-link-hover-color;
  }
}
@media (max-width: 767px) {
  .additional-filters {
    .btn-primary {
      margin: 5px 15px;
    }
  }
}