@use "sass:math";
.row.row-equal {
  padding-right: math.div($grid-gutter-width, 4);
  padding-left:  math.div($grid-gutter-width, 4);
  margin-right: math.div($grid-gutter-width, -2);
  margin-left:  math.div($grid-gutter-width, -2);

  [class*="col-"] {
    padding-right: math.div($grid-gutter-width, 4);
    padding-left:  math.div($grid-gutter-width, 4);
  }
}
