@use "sass:math";
// IE10&11 Flexbox fix
@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

body.order-detail {
  padding-bottom: 60px;
}

// app-dashboard and app-root are Angular2+ selectors. You can add here your own selectors if you need.

.app,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  flex: 0 0 $navbar-height;
}

.app-footer {
  flex: 0 0 $footer-height;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
  }

  .sidebar {
    // $sidebar-width is the width of the columns
    flex: 0 0 $sidebar-width;
    // put the nav on the left
    order: -1;
  }

  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 $aside-menu-width;
  }
}

//
// header
//
.header-fixed {
  .app-header {
    position: fixed;
    z-index: $zindex-sticky;
    width: 100%;
    top: 0;
    box-shadow: 0px 0px 7px 0px #d2d0d0;
    background: $top-nav-bg;
    flex-wrap: nowrap;
  }
  .app-body {
    margin-top: $navbar-height;
  }
}

//
// Sidebar
//
.sidebar-hidden {
  .sidebar {
    margin-left: -$sidebar-width;
  }
}

.sidebar-fixed {
  .sidebar {
    position: fixed;
    width: $sidebar-width;
    height: 100%;
    top: $navbar-height;
    .sidebar-nav {
      height: calc(100vh - calc(#{$navbar-height}));
    }
  }
  &.order-detail {
    .sidebar {
      .sidebar-nav {
        height: calc(100vh - calc(#{$navbar-height} + #{$horizontal-menu-height} + #{$fixed-bottom-menu-height}));
      }
    }
  }
  .main,
  .app-footer {
    margin-left: $sidebar-width;
  }

  &.sidebar-hidden {
    .main,
    .app-footer {
      margin-left: 0;
    }
  }
}

.sidebar-off-canvas {
  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    height: 100%;

    .sidebar-nav {
      height: calc(100vh - #{$navbar-height});
    }
  }
}
.owl-dateTime-inputWrapper {
  font: normal normal normal 14px/1 FontAwesome;
  .owl-datetime-main-input {
    height: auto;
    padding: 0.5rem 0.75rem 0.5rem 2rem;
    line-height: 1.25;
    font-family: $font-family-base;
  }
  &:before {
    content: "\F073";
    position: absolute;
    left: 6px;
    top: 8px;
    font-size: 18px;
  }
}
.w-auto {
  width: auto !important;
}
.icon-holder {
  font-size: 20px;
}
.address-block {
  font-size: 13px;
  color: #444;
  label {
    min-width: 100px;
    margin-bottom: 0;
  }
}
.container-fluid {
  min-height: calc(100vh - 200px); // set min height to keep copyright text always at bottom
}
.copyright {
  padding-left: 15px;
  margin-top: 10px;
  font-size: 12px;
  position: relative;
}
@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      flex: 0 0 $sidebar-compact-width;
    }

    &.sidebar-hidden {
      .sidebar {
        margin-left: -$sidebar-compact-width;
      }
    }

    &.sidebar-fixed {
      .main,
      .app-footer {
        margin-left: $sidebar-compact-width;
      }

      .sidebar {
        width: $sidebar-compact-width;
      }

      &.sidebar-hidden {
        .main,
        .app-footer {
          margin-left: 0;
        }
      }
    }
  }

  .sidebar-minimized {
    .sidebar {
      flex: 0 0 $sidebar-minimized-width;
    }

    &.sidebar-hidden {
      .sidebar {
        margin-left: -$sidebar-minimized-width;
      }
    }

    &.sidebar-fixed {
      .main,
      .app-footer {
        margin-left: $sidebar-minimized-width;
      }

      .sidebar {
        width: $sidebar-minimized-width;
      }

      &.sidebar-hidden {
        .main,
        .app-footer {
          margin-left: 0;
        }
      }
    }
  }
}

//
// Aside Menu
//
.aside-menu-hidden {
  .aside-menu {
    margin-right: -$aside-menu-width;
  }
}

.aside-menu-fixed {
  .aside-menu {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 1022;
    overflow-y: auto;
    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }

  // .main, .app-footer {
  //   margin-right: $aside-menu-width;
  // }

  &.aside-menu-hidden {
    .main,
    .app-footer {
      margin-right: 0;
    }
  }
}

.aside-menu-off-canvas {
  .aside-menu {
    position: fixed;
    right: 0;
    z-index: $zindex-sticky - 1;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }
}

//
// Breadcrumb
//
.breadcrumb-fixed {
  .main {
    $breadcrumb-height: 2 * $breadcrumb-padding-y + $font-size-base + 1.5 * $spacer;
    padding-top: $breadcrumb-height;
  }

  .breadcrumb {
    position: fixed;
    top: $navbar-height;
    right: 0;
    left: 0;
    z-index: $zindex-sticky - 2;
  }

  // if sidebar + main + aside
  .main:nth-child(2) {
    .breadcrumb {
      right: $aside-menu-width;
      left: $sidebar-width;
    }
  }

  // if sidebar + main
  .main:first-child {
    .breadcrumb {
      right: $aside-menu-width;
      left: 0;
    }
  }

  // if  main + aside
  .main:last-child {
    .breadcrumb {
      right: 0;
    }
  }

  &.sidebar-minimized {
    .main .breadcrumb {
      left: $sidebar-minimized-width;
    }
  }

  &.sidebar-hidden,
  &.sidebar-off-canvas {
    .main .breadcrumb {
      left: 0;
    }
  }

  &.aside-menu-hidden,
  &.aside-menu-off-canvas {
    .main .breadcrumb {
      right: 0;
    }
  }
}
.popover {
  max-width: 350px;
  width: 100%;
  .popover-body {
    padding: 10px 15px;
  }
}
//
// Footer
//
.footer-fixed {
  .app-footer {
    position: fixed;
    bottom: 0;
    z-index: $zindex-sticky;
    width: 100%;
  }

  .app-body {
    margin-bottom: $footer-height;
  }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed,
    width $layout-transition-speed, flex $layout-transition-speed;
}
.breadcrumb {
  transition: left $layout-transition-speed, right $layout-transition-speed, width $layout-transition-speed;
}
.custom-control-label::before {
  border: 1px solid #ddd;
}

// Modal Tab content .

.modal {
  .tab-content {
    border: 0 none;
  }
  .nav-tabs {
    border-bottom: 2px solid #ddd;
  }
  .nav-tabs .nav-link {
    border: none;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .navbar .active.dropdown-toggle,
  .navbar .nav-tabs .active.dropdown-toggle {
    color: theme-color("primary");
    border: none;
    border-bottom: 2px solid theme-color("primary");
  }
  .nav-tabs .nav-link:hover {
    color: theme-color("primary");
    border: none;
  }
  .nav-tabs .nav-link.active:hover {
    border-bottom: 2px solid theme-color("primary");
  }
}
#artwork-current-tab-panel,
#artwork-gallery-tab-panel {
  &.tab-pane {
    padding-left: 0;
    padding-right: 0;
  }
}
.img-preview-wrap {
  align-items: center;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}

lib-salesonepro-confirmation-dialog {
  .modal-header {
    background: #f3f3f3;
    border: 0;
    .modal-title {
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  .modal-footer {
    border: 0;
  }
}
.stitch-table .popover-body {
  padding-top: 0;
}

//
// Mobile layout
//

@include media-breakpoint-down(md) {
  .app-header {
    position: fixed !important;
    z-index: $zindex-sticky;
    width: 100%;
    text-align: center;
    justify-content: flex-start;
    .navbar-toggler {
      @if (lightness($navbar-brand-bg) > 40) {
        color: $navbar-color;
      } @else {
        color: #fff;
      }
    }

    .navbar-brand {
      position: absolute;
      left: 50%;
      margin-left: - math.div($navbar-brand-width, 2);
    }
  }

  .app-body {
    margin-top: $navbar-height;
  }

  .sidebar {
    position: fixed;
    width: $mobile-sidebar-width;
    height: 100%;
    margin-left: -$mobile-sidebar-width;

    .sidebar-nav,
    .nav {
      width: $mobile-sidebar-width !important;
    }
  }

  .main,
  .app-footer {
    margin-left: 0 !important;
  }

  // .aside-menu {
  //   margin-right: - $aside-menu-width;
  // }

  .sidebar-hidden {
    .sidebar {
      margin-left: -$mobile-sidebar-width;
    }
  }

  .sidebar-mobile-show {
    .sidebar {
      width: $mobile-sidebar-width;
      margin-left: 0;

      .sidebar-nav {
        height: calc(100vh - #{$navbar-height});
      }
    }

    .main {
      margin-right: -$mobile-sidebar-width !important;
      margin-left: $mobile-sidebar-width !important;
    }
  }

  .breadcrumb-fixed {
    .main .breadcrumb {
      right: 0 !important;
      left: 0 !important;
    }
  }
}
.review-thumb .isample-preview {
  width: 100px !important;
  min-height: auto !important;
  img {
    max-width: 100%;
  }
}
.gallery {
  .img-wrapper {
    height: 180px;
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .card-img-top {
    width: auto;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    padding: 3px;
    border-top-left-radius: calc(0.15rem - 1px);
    border-top-right-radius: calc(0.15rem - 1px);
  }

  .list-item-heading {
    font-size: 1rem;
  }
}
.table thead th.prod-list-name {
  width: 300px;
}
.product-review-table {
  .table {
    thead {
      th {
        font-size: 13px;
        padding: 0.6rem 0.3rem;
        &.status-wrap {
          width: 114px;
        }
      }
    }
    td {
      font-size: 13px;
      padding: 0.6rem 0.3rem;
      .form-control {
        padding: 0.375rem 0.6rem;
      }
    }
  }
}
