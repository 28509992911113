// Core Admin Variables

$body-color:                #444444 !default;
$enable-sidebar-nav-rounded:          false !default;

$border-color:                        $gray-200 !default;
$layout-transition-speed:             .25s !default;

// Social Colors

$facebook:                            #3b5998 !default;
$twitter:                             #00aced !default;
$linkedin:                            #4875b4 !default;
$google-plus:                         #d34836 !default;
$flickr:                              #ff0084 !default;
$tumblr:                              #32506d !default;
$xing:                                #026466 !default;
$github:                              #4183c4 !default;
$html5:                               #e34f26 !default;
$openid:                              #f78c40 !default;
$stack-overflow:                      #fe7a15 !default;
$youtube:                             #b00 !default;
$css3:                                #0170ba !default;
$dribbble:                            #ea4c89 !default;
$google-plus:                         #bb4b39 !default;
$instagram:                           #517fa4 !default;
$pinterest:                           #cb2027 !default;
$vk:                                  #45668e !default;
$yahoo:                               #400191 !default;
$behance:                             #1769ff !default;
$dropbox:                             #007ee5 !default;
$reddit:                              #ff4500 !default;
$spotify:                             #7ab800 !default;
$vine:                                #00bf8f !default;
$foursquare:                          #1073af !default;
$vimeo:                               #aad450 !default;

// Navbar

$navbar-height:                       60px !default;
$navbar-bg:                           $secondary;
$navbar-border: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
)  !default;
$navbar-brand-width:                  155px !default;
$navbar-brand-bg:                     #cacdd8 !default;
$navbar-brand-logo:                   url('/assets/img/logo.png') !default;
$navbar-brand-logo-size:              80px auto !default;
$navbar-brand-border: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$navbar-color:                        $gray-700 !default;
$navbar-hover-color:                  theme-color("primary") !default;
$navbar-active-color:                 theme-color("primary") !default;
$navbar-disabled-color:               #b1b1b1 !default;

// Sidebar

$sidebar-width:                       270px !default;
$sidebar-padding:                     0 !default;
$sidebar-minimized-width:             50px !default;
$sidebar-minimized-height:            $sidebar-minimized-width !default;
$sidebar-compact-width:               150px !default;
$sidebar-compact-height:              $sidebar-compact-width !default;
$sidebar-color:                       #fff !default;
$sidebar-bg:                          #F8F8F8 !default;
$sidebar-header-bg:                   rgba(0,0,0,.2) !default;
$sidebar-footer-bg:                   rgba(0,0,0,.2) !default;
$sidebar-borders:                     none !default;
$mobile-sidebar-width:                220px !default;

// Sidebar Navigation

$sidebar-nav-color:                   #3d3d3d !default;
$sidebar-nav-title-padding-y:         .75rem !default;
$sidebar-nav-title-padding-x:         1rem !default;
$sidebar-nav-title-color:             $gray-200 !default;
$sidebar-nav-link-padding-y:          .65rem !default;
$sidebar-nav-link-padding-x:          0.5rem !default;
$sidebar-nav-link-color:              #333 !default;
$sidebar-nav-link-bg:                 #f4f4f4 !default;
$sidebar-nav-link-icon-color:         #999da6 !default;
$sidebar-nav-link-borders:            0 !default;

$sidebar-nav-link-hover-color:        #fff !default;
$sidebar-nav-link-hover-bg:           #b7b7b7 !default;
$sidebar-nav-link-hover-icon-color:   #fff !default;
$sidebar-nav-link-hover-borders:      0 !default;

$sidebar-nav-link-active-color:       #3d3d3d !default;
$sidebar-nav-link-active-bg:          #E7EAF3 !default;
$sidebar-nav-link-active-icon-color:  #999da6 !default;
$sidebar-nav-link-active-borders:     0 !default;

$sidebar-nav-dropdown-color:          #6F7279 !default;
$sidebar-nav-dropdown-active-color:   #fff !default;
$sidebar-nav-dropdown-bg:             #EBEBEB !default;
$sidebar-nav-dropdown-borders:        0 !default;

// Top Navigation

$top-nav-bg:                          #ffffff !default;
$top-nav-color:                       $body-color !default;
$top-nav-borders: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;
$top-nav-ul-borders: (
  all: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$top-nav-hover-color:                 #fff !default;
$top-nav-hover-bg:                    theme-color("primary") !default;
$top-nav-active-color:                #fff !default;
$top-nav-active-bg:                   theme-color("primary") !default;
$top-nav-height:                      $navbar-height - 15px !default;

// Breadcrumb

$breadcrumb-color:                    #595959;
$breadcrumb-borders: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

// Aside

$aside-menu-width:                    500px !default;
$aside-menu-color:                    $gray-800 !default;
$aside-menu-bg:                       #fff !default;
$aside-menu-borders: (
  left: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$aside-menu-nav-padding-y:            .75rem !default;
$aside-menu-nav-padding-x:            1rem !default;

// Footer

$footer-height:                       50px !default;
$footer-bg:                           $gray-100 !default;
$footer-color:                        $body-color !default;
$footer-borders: (
  top: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

// Cards

$card-icon-bg:                        transparent !default;
$card-icon-color:                     $body-color !default;


//link color
$secondary-link-color:                #4DC0E6;
$secondary-link-hover-color:          #268bad;

$text-link-color:                     #4CB050 !default;
$text-link-hover-color:               darken($text-link-color, 15%) !default; 

//menu height

$horizontal-menu-height:              51px;
$fixed-bottom-menu-height:            52px;
$fixed-bottom-submenu-color:          #474373; 

//angular date picker z-index

$datepicker-overlay-index:            1050;

// meeting background - calendar 

$bg-meeting:                          #6b75f8;
$menu-icon-color:                     #555555;
