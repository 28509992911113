.breadcrumb {
	position: relative;
	background: none;
	margin-bottom: 0;
	.breadcrumb-item {
		a {
			color: $breadcrumb-color;
		}
		&.active span {
			font-weight: 600;
			color: $breadcrumb-color;
		}
		&:before {
			position: relative;
			top: 1px;
			color: $breadcrumb-color;
		}
	}
}
