.app-header.navbar {
  position: relative;
  flex-direction: row;
  height: $navbar-height;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;
  @include borders($navbar-border);

  .navbar-brand {
    display: inline-block;
    width: $sidebar-width;
    height: $navbar-height;
    padding: $navbar-padding-y $navbar-padding-x;
    margin-right: 0;
    background-image: $navbar-brand-logo;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $navbar-brand-bg;
    // background-size: $navbar-brand-logo-size;
    // background-position: 18px center;
  }

  .navbar-toggler {
    color: $menu-icon-color;
    &.d-md-down-none {
      color: $navbar-bg;
      // padding: 18px 13px 18px 14px;
      border-radius: 0;
      font-size: 30px;
      // margin-left: 75px;
      &:focus {
        outline: none;
      }
    }
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
    &.d-md-down-none {
      .nav-item {
        border-right: 1px solid #777D90;
        .nav-link {
            padding-top: 17px;
            padding-bottom: 17px;
            font-weight: 600;
        }
        &.active {
          background: #fff;
          border-bottom: 2px solid theme-color("primary");
        }
      }
    }
    .navbar-toggler {
      color: $white;
      &.d-md-down-none {
        color: $white;
        background: $navbar-bg;
      }
    }
    .search {
      color: #fff;
      i {
        font-size: 20px;
        margin-right: 5px;
        cursor: pointer;
      }
      input {
        background: transparent;
        border: none;
        color: #fff;
        font-size: 16px;
        width: 200px;
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          color: #fff;
        }
      }
    }
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 600;
      height: $navbar-height;
      align-items: center;
      display: flex;
      padding: 0 16px;
      @media (max-width: 1250px) {
        padding: 0 10px;
        font-size: 14px;
      }
      @media (max-width: 1150px) {
        padding: 0 5px;
        font-size: 12px;
      }
      @media (max-width: 1120px) {
        padding: 0 4px;
        font-size: 11px;
      }
      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -22px;
        margin-left: 0;
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
      }
    }
    &.active {
      .nav-link {
        border-bottom: 3px solid theme-color("primary");
        margin-bottom: -3px;
      }
    }
    &.dropdown {
      .dropdown-toggle {
        padding-right: 5px;
      }
      &.show {
        .dropdown-toggle {
          // background: #4E5363;
          padding-bottom: 8px;
          padding-top: 9px;
        }
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
    color: #61677A;
    border-bottom: 0;
  }
}

.navbar-brand {
  color: $navbar-active-color;

  @include hover-focus {
    color: $navbar-active-color;
  }
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0,0,0,.075);
}

.horizontal-menu-fixed {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 60px;
  border-bottom: 1px solid #ddd;
  background: #fff;
  height: $horizontal-menu-height;
  .user-nav {
    width: $sidebar-width;
    color: $sidebar-color;
    li {
      a{
        color: #524d4d;
        font-weight: 600;
      }
    }
  }
  .top-nav {
    position: relative;
    flex-direction: row;
    padding: 0;
    margin: 0;
    background: #fff;
    .navbar-nav {
      flex-direction: row;
      align-items: center;
    }
    .nav-item {
      .nav-link {
        padding-top: 13px;
        padding-bottom: 11px;
        font-weight: 400;
        font-size: 16px;
      }
      &.active {
        border-bottom: 2px solid #4caf50;
      }
      .home-icon {
        font-size: 25px;
        line-height: 20px;
      }
    }
  }
}

// customer detail nav 
.cust-detail-wrap {
  position: relative;
  .nav-tabs {
    .nav-item {
      .nav-link {
        background: #E6E6E6;
        border: 1px solid #E6E6E6;
        border-bottom: none;
        margin-right: 3px;
        color: #959595;
        min-width: 125px;
        border-radius: 0;
        margin-bottom: 5px;

        &.active {
          background: #fff;
          color: #29363d;
          margin-bottom: 0;
          padding-bottom: 13px;
        }
        lib-salesonepro-icon {
          font-size: 18px;
        }
      }
    }
  }
  .tab-content {
    border: 1px solid #E6E6E6;
  }
  #billing_settings-header ,
  #shipping_settings-header {
    a {
      color:#fff;
      display: block;
    }
  }
}
