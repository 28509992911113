.pagination-datatables, .pagination {
  margin-bottom: 0;
  li {
    @extend .page-item;

    a {
      @extend .page-link;
      &:hover {
        background: transparent;
      }
    }
    &.active {
      a {
        border-radius: 2px;
      }
    }
  }
}

.label-pill {
  border-radius: 1rem !important;
}

// temp fix for Vue & React

// Open state for the dropdown
.open, .show {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }
  > .dropdown-menu-right {
    left: 0;
    right: auto; // Reset the default from `.dropdown-menu`
  }
  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

//
//.modal-open .modal {
//  display: block;
//}

// navbar dropdown fix
.navbar .dropdown-toggle {
  @extend .nav-link;

  .img-avatar {
    height: $navbar-height - 20px;
    margin: 0 10px;
  }
}

.card-block {
  @extend .card-body;
}
