@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  div {
    width: 50px;
    height: 50px;
    border: 5px solid theme-color("primary");
    border-radius: 50%;
    border-top-color: #bbe1bc;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: 1s spin infinite linear;
 }
}