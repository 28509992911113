.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-inverse {
  color: #fff;
}
@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}
.bg-gray {
  background: #F3F3F3 !important;
}